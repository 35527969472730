<template>
  <div class='compliance-speak'>
    <h2 class="title">{{$t('complianceTitle3')}}<br><span>{{$t('complianceSubtitle')}}</span></h2>

    <div class="compliance-speak__content">
      <div class="compliance-speak__descr">
        <p>{{$t('complianceText3')}}</p>
        <h3>{{$t('complianceTitle4')}}</h3>
        <span>{{$t('complianceText4')}}</span>
        <ul>
          <li v-for="(list, index) in $t('complianceTexts')" :key="index">{{list.text}}</li>
        </ul>
      </div>
      <div class="compliance-speak__img">
        <img src="@/assets/img/compliance/speak.jpg" alt="image">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ComplianceSpeak'
  }
</script>